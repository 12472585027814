import React from 'react'

import L4TabsLayout from '@components/layouts/l4-tabs-layout'

import DebtEquityL5 from './debt-equity'

const DebtEquityL4 = ({ exportable }: { exportable: boolean }) => {
  const tabs = [
    {
      label: 'Debt:Equity',
      desc: 'The ratio calculated by Total Liabilities divided by Total Equity',
      component: <DebtEquityL5 exportable={exportable} />,
    },
  ]

  return <L4TabsLayout tabs={tabs} />
}

export default DebtEquityL4
