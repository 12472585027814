import React, { useContext, useEffect, useState } from 'react'
import moment from 'moment'
import {
  Outlet,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom'
import { ToastContainer } from 'react-toastify'

import { useAuth0 } from '@auth0/auth0-react'
import FilterDebtDeal from '@components/filters/filter-debt-deal'
import Loading from '@components/loading-overlay/loading'
import { ACCESS_TOKEN_STORAGE_KEY, FAV_COMPANY } from '@constants/app'
import { AUTH_TOS_TIMESTAMP } from '@constants/config'
import AuthContext from '@contexts/auth'
import { setMetadata } from '@contexts/auth'
import { ArrowPathIcon } from '@heroicons/react/24/outline'
import Company from '@interfaces/company'
import { Alert } from '@material-tailwind/react'
import CompanyService from '@services/api-analytics/company'
import ExchangeService from '@services/api-analytics/currency-exchange'

import Sidebar from './sidebar'
import Sidemenu from './sidemenu'
import SidebarFooter from './sidemenu-footer'

import 'react-toastify/dist/ReactToastify.css'

const AuthLayout = () => {
  const {
    userMetadata,
    setUserMetadata,
    company: companyDetails,
    setCompanyDetails,
    setOptionFilters,
    setActiveFilters,
    setAppliedFilters,
    setShowFilter,
    showSidemenu,
    setShowSidemenu,
    setLoadingData,
  } = useContext(AuthContext)
  const [isTokenSet, setIsTokenSet] = useState(false)
  const [loadingCompany, setLoadingCompany] = useState(true)
  const [getCompanyError, setGetCompanyError] = useState('')
  const [getAuthResponse, setAuthResponse] = useState('')
  const [companyList, setCompanyList] = useState<Company[]>([])

  const [expandMenu, setExpandMenu] = useState<boolean>(false)

  const {
    isAuthenticated,
    isLoading,
    loginWithRedirect,
    getAccessTokenSilently,
    user,
  } = useAuth0()
  const location = useLocation()
  const { pathname } = location
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const provider: string | undefined = user?.sub?.split('|')?.[0]

  useEffect(() => {
    if (!user || !isTokenSet) {
      return
    }

    const companies = userMetadata?.companies
    const company_slugs: string | undefined = companies
      ? Object.keys(companies).join(',')
      : undefined
    const is_superadmin = userMetadata?.isSuperadmin
    const TOSAccepted: boolean | undefined = userMetadata?.tosAccepted
    const TOSTimestamp: number | undefined = userMetadata?.tosTimestamp

    if (!company_slugs && !is_superadmin) {
      if (provider === 'auth0') {
        setLoadingCompany(false)
        setGetCompanyError(
          'Your account is not associated with any company. Please contact your administrator.'
        )
        return
      } else {
        navigate('/auth-handler')
        return
      }
    }

    if (
      !TOSAccepted ||
      !TOSTimestamp ||
      moment(TOSTimestamp).unix() < Number(AUTH_TOS_TIMESTAMP)
    ) {
      navigate('/auth-callback')
      return
    }

    const favCompany = localStorage.getItem(FAV_COMPANY)

    setLoadingCompany(true)
    CompanyService.getCompanies(company_slugs?.split(','))
      .then(res => {
        const companyResult = res.sort((a, b) => {
          return a.currencies_available?.length === 0
            ? 1
            : a.legal_name > b.legal_name
            ? 1
            : -1
        })

        setCompanyList(companyResult)
        !!searchParams.get('pid')
          ? changeCompany(
              companyResult.filter(
                p => p.slug_name == searchParams.get('pid')
              )[0]
            )
          : !!favCompany
          ? changeCompany(
              companyResult.filter(p => p.slug_name == favCompany)[0]
            )
          : changeCompany(companyResult?.[0])
      })
      .catch(e => {
        console.error(e)

        setGetCompanyError('There was an error obtaining your company details.')
      })
      .finally(() => {
        setLoadingCompany(false)
      })
  }, [user, userMetadata, isTokenSet])

  const updateUrlParam = (key: string, value: string) => {
    const searchParams = new URLSearchParams(location.search)
    searchParams.set(key, value)
    navigate(`?${searchParams.toString()}`)
  }

  const changeCompany = (c: Company) => {
    updateUrlParam('pid', c.slug_name)

    setCompanyDetails({
      ...c,
      date_end: moment(c.date_end).isAfter(moment())
        ? moment().format('YYYY-MM-DD')
        : c.date_end,
      currencies_available: c.currencies_available?.map(x => ({
        from_currency: ExchangeService.getLatestISOCode(x?.from_currency ?? ''),
        to_currency: ExchangeService.getLatestISOCode(x?.to_currency ?? ''),
      })),
    })
    setOptionFilters({})
    setAppliedFilters({})
    setActiveFilters({})
    setShowFilter(false)
    setShowSidemenu(true)
    setLoadingData({})
  }

  useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      loginWithRedirect()
    }
  }, [isAuthenticated, isLoading])

  useEffect(() => {
    if (!isAuthenticated) {
      return
    }

    getAccessTokenSilently().then(token => {
      localStorage.setItem(ACCESS_TOKEN_STORAGE_KEY, token)
      if (!userMetadata) {
        setMetadata(user, setUserMetadata, setAuthResponse)
        return
      }
      setIsTokenSet(true)
    })
  }, [isAuthenticated, userMetadata])

  useEffect(() => {
    companyDetails && updateUrlParam('pid', companyDetails?.slug_name)
  }, [pathname])

  if (!isLoading && !isTokenSet && getAuthResponse.length > 0) {
    return (
      <div className="flex flex-col h-[calc(100dvh)] px-[25%] bg-primary-surface-2 justify-center items-center">
        <Alert className="bg-danger-main text-center">{getAuthResponse}</Alert>
      </div>
    )
  }

  if (isLoading || !isTokenSet || loadingCompany) {
    return (
      <div className="flex flex-col w-screen h-[calc(100dvh)] bg-primary-surface-2 justify-center items-center">
        <ArrowPathIcon className="animate-spin text-primary-main w-8" />
      </div>
    )
  }

  if (getCompanyError.length > 0) {
    return (
      <div className="flex flex-col h-[calc(100dvh)] px-[25%] bg-primary-surface-2 justify-center items-center">
        <Alert className="bg-danger-main text-center">{getCompanyError}</Alert>
      </div>
    )
  }

  if (pathname === '/404') {
    return <Outlet />
  }

  return (
    <div className="w-screen h-[calc(100dvh)] bg-primary-surface-2 flex">
      <div className="hidden">
        <FilterDebtDeal />
      </div>
      <div
        className={`${
          expandMenu ? 'h-[calc(100dvh)] md:h-menu' : 'h-sidebar'
        } lg:h-[calc(100dvh)] w-screen flex flex-col bg-neutral-white fixed z-[999] ${
          showSidemenu ? 'lg:w-side' : 'lg:w-sidebar'
        }  shadow-md`}
      >
        <div
          onClick={() => setExpandMenu(!expandMenu)}
          className={`w-screen h-[calc(100dvh)] bg-[rgba(0,0,0,.3)] fixed top-0 left-0 z-0 ${
            expandMenu ? 'block' : 'hidden'
          } lg:hidden`}
        />
        <div className="flex h-full">
          <Loading />
          <Sidebar isExpanded={expandMenu} />
          <Sidemenu
            show={showSidemenu}
            onShow={() => setShowSidemenu(!showSidemenu)}
            expanded={expandMenu}
            onExpand={() => setExpandMenu(!expandMenu)}
          />
        </div>
        <SidebarFooter
          isExpanded={showSidemenu}
          companies={companyList}
          onSelectCompany={changeCompany}
        />
      </div>
      <div
        className={`flex flex-col w-screen ${
          showSidemenu
            ? 'lg:w-lg-content-with-side-expand lg:ml-side'
            : 'lg:w-lg-content-with-side-collapse lg:ml-sidebar'
        } h-content-with-top-nav lg:h-[calc(100dvh)] mt-sidebar lg:mt-0 ml-0  overflow-x-hidden overflow-y-auto`}
      >
        <Outlet />
      </div>
      <ToastContainer className="z-[9999]" />
    </div>
  )
}

export default AuthLayout
