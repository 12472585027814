import React, { FC, useContext, useEffect, useRef, useState } from 'react'
import moment from 'moment'
import { useLocation } from 'react-router-dom'
import { useSearchParams } from 'react-router-dom'

import Button from '@components/atoms/button'
import AuthContext from '@contexts/auth'
import {
  AdjustmentsVerticalIcon,
  EyeIcon,
  EyeSlashIcon,
  HomeIcon,
} from '@heroicons/react/24/outline'
import { Filter, FilterItem } from '@interfaces/filter'
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  Breadcrumbs,
  Card,
  CardBody,
  CardFooter,
} from '@material-tailwind/react'

export interface BaseLayoutProps {
  /**
   * filters section. empty by default
   */
  filters?: {
    left?: any
    middle?: any
    right?: any
    className?: string
  }
  /**
   * page title
   */
  title: string

  /**
   * whether reset button disabled or not. Depend on which page observing context.
   */
  disableReset?: boolean

  /**
   * right footer content in jsx
   * currently used for export button
   */
  rightFooterContent?: any

  /**
   * turn off breadcrumbs row
   */
  disableBreadcrumbs?: boolean
}

const BaseLayout: FC<BaseLayoutProps> = ({
  children,
  filters,
  disableReset = true,
  rightFooterContent,
  disableBreadcrumbs,
}) => {
  const {
    showFilter,
    setShowFilter,
    company,
    optionFilters,
    activeFilters,
    setActiveFilters,
    appliedFilters,
    setAppliedFilters,
  } = useContext(AuthContext)
  const location = useLocation()

  const { rateFetching, calculationDateIsFetching } = activeFilters

  const exportDivRef = useRef<HTMLDivElement>(null)
  const [searchParams] = useSearchParams()

  const tab = searchParams.get('tab')

  const disableApply =
    Object.keys(appliedFilters).length == 0 ||
    JSON.stringify(activeFilters) === JSON.stringify(appliedFilters) ||
    rateFetching ||
    calculationDateIsFetching

  const resetFilter = () => {
    const selectedRate = optionFilters.rates?.find(
      (r: { code: string; rate: number }) =>
        r.code == company?.currencies_available?.[0]?.to_currency
    )

    const resetDateEnd =
      tab == 'document-centre'
        ? moment().add(1, 'month')
        : moment(company?.date_end).isAfter(moment())
        ? moment()
        : moment(company?.date_end)

    const initialFilters = {
      currency: company?.currencies_available?.[0]?.to_currency,
      dateStart: moment(company?.date_start).format('YYYY-MM-DD'),
      dateEnd: resetDateEnd,
      dateEndCohort: moment(company?.date_end_cohort).isAfter(moment())
        ? moment()
        : moment(company?.date_end_cohort),
      range: 0,
      activeType: 'All',
      categoryTypes: optionFilters.categoryTypeOptions
        ?.find((cto: Filter) => cto.type_key === 'All')
        ?.type_val?.map((tv: FilterItem) => tv.id),
      activeDebtDeal: 0,
      dateStartCohort: moment.utc(company?.date_start),
      financialEntity: optionFilters.financialEntities?.length
        ? optionFilters.financialEntities[0].entity_id
        : '',
      financialEntityCurrency: optionFilters.financialEntities?.length
        ? optionFilters.financialEntities[0].currency
        : '',
      rate: selectedRate?.rate ? selectedRate.rate : 1,
      docStatus: optionFilters.docStatuses[0],
      docCategory: optionFilters.docCategories[0],
    }

    setActiveFilters(initialFilters)
    setAppliedFilters(initialFilters)
  }

  const applyFilter = () => {
    setAppliedFilters(activeFilters)
    setShowFilter(!showFilter) //closes filters when new filters are selected
  }
  //preparing breadcrumbs.. yum toast ;)
  const breadcrumbsArr = window.location.pathname.split('/')

  /**
   * temp workaround for this issue
   * https://github.com/creativetimofficial/material-tailwind/pull/206
   */
  const [mounted, setMounted] = useState<boolean>(false)

  useEffect(() => {
    setTimeout(() => {
      setMounted(true)
    }, 500)
    return () => {
      setMounted(false)
    }
  }, [])
  /** end workaround */

  useEffect(() => {
    setShowFilter(false)
  }, [location])

  return (
    <div className="overflow-y-auto px-6">
      <Card className="rounded-lg my-6 px-6">
        {/*only display breadcrumbs when not home*/}
        {!disableBreadcrumbs && breadcrumbsArr.length > 1 && (
          <Breadcrumbs
            separator=">"
            className={
              filters
                ? 'bg-white my-2 absolute left-0 cursor-default pl-6' //relative within filters
                : 'bg-white my-2 cursor-default'
            }
          >
            {/*display home icon for origin & capitalise the first letters of the rest*/}
            {breadcrumbsArr.map((crumb, i) => (
              <a key={i} href="#" className="text-xs md:text-sm capitalize">
                {crumb == '' ? (
                  <HomeIcon className="w-4 md:w-5" />
                ) : (
                  crumb.replaceAll('-', ' ')
                )}
              </a>
            ))}
          </Breadcrumbs>
        )}
        {filters && (
          <Accordion
            className={filters.className}
            open={showFilter && mounted}
            icon={
              !showFilter ? (
                <EyeIcon
                  className="cursor-pointer"
                  onClick={() => setShowFilter(!showFilter)}
                />
              ) : (
                <EyeSlashIcon
                  className="cursor-pointer"
                  onClick={() => setShowFilter(!showFilter)}
                />
              )
            }
          >
            <AccordionHeader
              className={`flex justify-between relative cursor-default ${
                showFilter ? '' : 'border-0'
              }`}
            >
              <span>{''}</span>
              <AdjustmentsVerticalIcon className="w-5 absolute right-10" />
            </AccordionHeader>
            <AccordionBody className="pt-0">
              <CardBody
                className={`grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 p-0 border-b border-b-neutral-border-2`}
              >
                <div className="py-6 md:col-span-2 pb-6 md:border-b md:border-b-neutral-border-2 lg:col-span-1 lg:border-0">
                  {filters?.left}
                </div>
                <div className="py-6 border-y border-y-neutral-border-2 md:border-y-0 md:border-r md:pr-6 lg:border-l lg:pl-6">
                  {filters?.middle}
                </div>
                <div className="py-6 md:pl-6">{filters?.right}</div>
              </CardBody>
              <CardFooter className="!p-0 !px-0 !pt-4 flex justify-between">
                <div className="flex flex-row">
                  <Button
                    className="mr-4"
                    onClick={resetFilter}
                    disabled={disableReset || rateFetching}
                  >
                    Reset Filters
                  </Button>
                  <Button
                    color="primary"
                    onClick={applyFilter}
                    disabled={disableApply}
                  >
                    Apply Filters
                  </Button>
                </div>
                <div
                  ref={exportDivRef}
                  id="exportdiv"
                  className="absolute right-0"
                >
                  {rightFooterContent}
                </div>
              </CardFooter>
            </AccordionBody>
          </Accordion>
        )}
      </Card>
      <div className="mb-6">{children}</div>
    </div>
  )
}

export default BaseLayout
